import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import useOutsideCloser from "../../lib/hooks/useOutsideCloser";
import useSendGAEvent from "../../lib/hooks/useSendGAEvent";

export default function SearchResult({ term }: { term: string }) {
  const [data, setData] = useState([{}])
  const wrapperRef = useRef(null)

  useOutsideCloser(wrapperRef);
  useSendGAEvent(term, 'Search');

  useEffect(() => {
    fetch(`/api/search?query=${term}`)
      .then(response => response.json())
      .then(json => {
        setData(json)
        if(wrapperRef.current !== null){
          //@ts-ignore
          wrapperRef.current.style.display = "block" 
        }
      })
      .catch(error => console.log('error', error));
  }, [term])

  if(data.length === 0) return (<></>)

  return (<>
    <div className="absolute top-10 z-50 hidden rounded-lg bg-heresy-bg-dark w-full lg:right-0 lg:top-[4.25rem]" ref={wrapperRef}>
      {data.map((entry: any) => (
        <Link passHref href={`/omnibus/${entry.link}`} key={entry.title}>
          <div className="mb-2 mt-2 p-2 hover:bg-heresy-bg-light hover:cursor-pointer" onClick={() => setData([])}>
            <span>{entry.title}</span>
            <p className="text-xs text-heresy-font-light text-ellipsis whitespace-nowrap overflow-hidden">{entry.story}</p>
          </div>
        </Link>
      ))}
    </div>
  </>)
}