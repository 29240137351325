import { useEffect, useState } from "react";
import classNames from "../../lib/classNames";
import SearchResult from "./SearchResult";

export default function SearchBox({display}: {display: boolean}) {
  const [query, setQuery] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchTerm(query), 750);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <>
      <div className={classNames(display ? 'block' : 'hidden', "lg:block flex flex-col w-full")}>
        <input
          type="search"
          placeholder='Search'
          value={query}
          onChange={event => setQuery(event.target.value)}
          className='appearance-none block p-2 text-sm w-full text-white bg-heresy-bg-light hover:bg-heresy-bg-light rounded-lg border-l-gray-50  border-gray-300 placeholder:text-gray-400 placeholder:hover:text-white'
        />
        {searchTerm && <SearchResult term={searchTerm}/>}
      </div>
    </>
  );
}