function useSendGAEvent(eventValue: any, label: string) {

  if(eventValue){
      //@ts-ignore
    window.gtag('event', label, {
      'event_category': 'general',
      'event_label': label,
      'value': eventValue
    });
  }
}

export default useSendGAEvent;