import NavigationBar from "../navigation/NavigationBar";

export default function Layout({children, activePage}: any ) {
  return (
    <div className="text-heresy-font-default">
      <NavigationBar />
        <div className="bg-heresy-bg-light rounded-md drop-shadow-xl">
          {children}
      </div>
    </div>
  );
}