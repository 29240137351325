import React from "react";
import { hasCookie, setCookie, getCookie } from "cookies-next";
import Link from "next/link";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CookieConsent = () => {
  const [cookieIsSet, setCookieIsSet] = React.useState(false);
  const [cookieConsent, setCookieConsent] = React.useState('denied');

  const pageHasCookie = hasCookie("cookie_consent")

  React.useEffect(() => {
    //@ts-ignore
    window.gtag("consent", "update", {
      'analytics_storage': cookieConsent
    })

  }, [cookieConsent]);

  function clickHandler(choice: boolean) {
    if (choice) {
      setCookieConsent('granted')
      setCookieIsSet(true)
      setCookie("cookie_consent", true)
    } else {
      setCookieConsent('denied')
      setCookieIsSet(true)
      setCookie("cookie_consent", false)
    }
  }

  if(pageHasCookie) {
    return (<></>)
  }

  return (
    <div className={classNames(
      cookieIsSet === true ? 'hidden' : 'flex',
      'my-3 mx-3 fixed bottom-0 left-0 right-0 px-3 md:px-4 py-3 justify-between items-center flex-col sm:flex-row gap-4 bg-heresy-bg-dark rounded-lg shadow'
    )}>

      <div className='text-center'>
        <p>This site uses cookies to measure and improve your experience.</p>
      </div>
      <div className='flex gap-2'>
        <button className='px-3 py-2 rounded-md border-gray-900 hover:text-white' onClick={() => clickHandler(false)}>Decline</button>
        <button className='bg-heresy-bg-light px-3 py-2 rounded-lg hover:bg-heresy-primary hover:text-white' onClick={() => clickHandler(true)}>Allow Cookies</button>
      </div>
    </div>
  );
};

export default CookieConsent;