import '../styles/globals.css'
import React from 'react';
import Layout from '../components/layout/Layout'
import ErrorBoundary from '../components/error-boundary';
import Link from 'next/link';
import CookieConsent from '../components/cookie-consent';
import GoogleAnalytics from '../components/google-analytics';

export default function MyApp({ Component, pageProps }: any) {
  return (
    <ErrorBoundary>
      <Layout>
        <GoogleAnalytics GA_MEASUREMENT_ID='G-9W21M22Z2F'/>
        <Component {...pageProps} />
        <footer className="">
        <div className='p-8 flex justify-evenly flex-wrap'>
          <div className='m-1'>
            <Link href="/about-us">About us</Link>
          </div>
          <div className='m-1'>
            <Link href="/privacy">Privacy</Link>
          </div>
          <div className='m-1'>
            <span>© 2023 heresyomnibus.com</span>
          </div>
        </div>
      </footer>
      <CookieConsent />
      </Layout>
    </ErrorBoundary>
  )
}