import { Fragment, useState } from 'react'
import { Disclosure, Popover, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';
import Link from 'next/link';

import SearchBox from './SearchBox';
import classNames from '../../lib/classNames';

const projectName = 'Horus Heresy Omnibus Project'

function constructNavigationObject(data: any) {
  const array = data.routes;

  const omnibusChilds: any[] = []

  array.forEach((element: string) => {
    omnibusChilds.push({ name: element, href: `/omnibus/${element}`, title: element.replaceAll('-', ' ').toUpperCase() })
  });

  const nav: any[] = []
  nav.push({ name: 'Omnibus', childs: omnibusChilds })
  nav.push({ name: 'Omnibus Map', href: '/reading-order' })
  nav.push({ name: 'FAQ', href: '/faq' })
  nav.push({ name: 'Articles', href: '/article' })
  nav.push({ name: 'About us', href: '/about-us' })

  return nav;
}


export default function NavigationBar() {

  const [navMenu, setNavMenu] = useState<any>([{}])
  const [isLoading, setLoading] = useState(false)
  const [searchBarIsDisplayed, setSearchBarIsDisplayed] = useState(false);

  function handleSeachButtonClick() {
    setSearchBarIsDisplayed(!searchBarIsDisplayed);
    setTimeout(() => {
      const input = document.getElementById('search-button')
      if(input){
        input.focus();
        input.click();
      }
    }, 100);
  }

  useEffect(() => {
    setLoading(true)
    fetch('/api/routes')
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        const navArray = constructNavigationObject(data)
        setNavMenu(navArray)
      })
  }, [])

  const route = useRouter();

  return (
    <Disclosure as="nav" className="bg-heresy-bg-default">
      {({ open }) => (
        <>
          <div className="px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-heresy-bg-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className={classNames(searchBarIsDisplayed ? 'hidden md:flex' : '', "flex-1 flex items-center justify-center sm:items-stretch sm:justify-start")}>
                <div className="flex items-center">
                  <h1 className='text-heresy-primary'><Link href="/">{projectName}</Link></h1>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4 items-center">
                    {navMenu.map((item: any) => (
                      item.childs ?
                        <>
                          <Popover className="relative" key={`key-${item}`}>
                            {({ open }) => (
                              <>
                                <Popover.Button
                                  className={`
                ${open ? 'bg-heresy-bg-light' : ''}
                text-white hover:bg-heresy-bg-light group px-3 py-2 rounded-md inline-flex items-center text-sm font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                  <span>Omnibus</span>
                                  <ChevronDownIcon
                                    className={`ml-2 h-5 w-5 text-heresy-primary`}
                                    aria-hidden="true"
                                  />
                                </Popover.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-full sm:px-0 lg:max-w-3xl">
                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                      <div className="relative grid gap-8 bg-heresy-bg-default p-7 lg:grid-cols-2">
                                        {item.childs.map((item: any) => (
                                          <a
                                            key={item.name}
                                            href={item.href}
                                            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-heresy-bg-light focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                          >
                                            <div className="ml-4">
                                              <p className="text-sm font-medium text-heresy-font-default">
                                                {item.title}
                                              </p>
                                            </div>
                                          </a>
                                        ))}
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </> :
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            route.pathname === item.href ? 'bg-heresy-bg-dark text-white' : 'text-gray-300 hover:bg-heresy-bg-light hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={route.pathname === item.href ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                    ))}
                  </div>
                </div>
                <div>

                </div>
              </div>
              <div className={classNames(searchBarIsDisplayed ? 'relative w-full md:static md:w-auto' : '' ,'flex')}>
                <SearchBox display={searchBarIsDisplayed}/>
                <button type="button" id="seach-button" className={classNames(searchBarIsDisplayed ? "" : "" ,"relative right-0 p-2 ml-2 mt-[1px] mb-[1px] text-sm font-medium rounded-lg hover:bg-heresy-bg-light text-gray-400 hover:text-white")} 
                  onClick={handleSeachButtonClick} 
                  >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  <span className="sr-only">Search</span>
                </button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navMenu.map((item: any) => (
                item.childs ?
                  <>
                    <Disclosure key={`key-${item.name}`}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-heresy-bg-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="sr-only">Open Omnibus menu</span>
                            <span className={classNames(
                              'text-gray-300 hover:bg-heresy-bg-light hover:text-white',
                              'block px-1 rounded-md text-base font-medium'
                            )}>Omnibus</span>
                            <ChevronDownIcon
                              className={`${open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-heresy-primary`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel >
                            {item.childs.map((child: any) => (
                              <a
                                key={child.name}
                                href={child.href}
                                className={classNames(
                                  route.asPath === child.href ? 'bg-heresy-bg-dark text-white' : 'text-gray-300 hover:bg-heresy-bg-light hover:text-white',
                                  'block px-3 py-2 rounded-md text-sm'
                                )}
                                aria-current={route.asPath === child.href ? 'page' : undefined}
                              >
                                {child.title}
                              </a>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </> :
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      route.pathname === item.href ? 'bg-heresy-bg-dark text-white' : 'text-gray-300 hover:bg-heresy-bg-light hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={route.pathname === item.href ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
